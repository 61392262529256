import { PCHomeRight } from '@/assets/icons/comm/PCHomeRight';
import { GetTextEnum } from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import {
  getForumMessagePage,
  getHelpCenterListApi,
  getJumpListApi,
} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { toJsonData } from '@/utils/socket';
import { history, useModel } from '@umijs/max';
import { useMount } from 'ahooks';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  const { appInfo } = useModel('appInfo');
  const emialUrl = appInfo?.['emialUrl'] ?? 'FCHcrypto@outlook.com';
  //获取隐私协议和用户协议
  const [privacyAgreementList] = useState([
    {
      title: t('隐私条款'),
      keyCode: GetTextEnum.PRIVACY_CLAUSE_CODE,
    },
    {
      title: t('实名认证'),
      keyCode: GetTextEnum.SYSTEM_REAL_NAME,
    },
    {
      title: t('用户协议'),
      keyCode: GetTextEnum.SYSTEM_USER,
    },
  ]);

  // 关于我们
  const {
    data: datalist = [] as any,
    loading,
    run: getDatalist,
  } = useReq(getJumpListApi, {
    cacheKey: 'CacheEnum.APP_NOTICELIST',
  });
  // 学院--论坛文章
  const {
    run: getForumMessagePageReq,
    data: forumMessagePageData = {} as any,
  } = useReq(getForumMessagePage, {
    manual: true,
  });
  useEffect(() => {
    getForumMessagePageReq({
      pageNo: 1,
      pageSize: 4,
    });
  }, []);

  //获取帮助中心列表
  const { data: helpCenterList = [] as any, run: getHelpCenterListReq } =
    useReq(getHelpCenterListApi, {
      onSuccess: (res) => {},
      formatResult: (res: any) => {
        return res?.data?.list ?? [];
      },
    });
  useMount(() => {
    getHelpCenterListReq();
    getDatalist();
  });
  const jumpIcon = useMemo(() => {
    if (!appInfo?.['JUMP_ICON']) return [];
    return toJsonData(appInfo?.['JUMP_ICON']);
  }, [appInfo]);
  return (
    <div className="pb-[10px] w-full mt-[220px] ">
      <div className="text-backContrastColor text-[60px] w-[1400px] mx-auto">
        <div className="text-center">{t('常见问题')}</div>
        <div>
          {helpCenterList?.map?.((item: any) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <div
                className="flex text-[28px] py-[50px] border-b-1 sm:cursor-pointer border-borderColor justify-between items-center"
                onClick={() => {
                  history.push(PageEnum.HELPARTICLEDETAIL + '?id=' + item.id);
                }}
              >
                <div>{item.title}</div>
                <PCHomeRight />
              </div>
            );
          })}
        </div>
      </div>
      <div className="max-w-[1400px] mx-auto mt-[68px]">
        <div className="flex items-center gap-2">
          <img className="w-[32px]" src={appInfo?.['APP_BASE_LOGO']} alt="" />
          <h3 className="text-2xl !mb-0">{appInfo?.['COMPANY_NAME']}</h3>
        </div>
        <p className="w-[300px] leading-[2] text-sm text-auxiliaryTextColor">
          {appInfo?.['COMPANY_PROFILE']}
        </p>
        <div className="flex items-center  gap-7">
          {jumpIcon.map((item: any) => {
            return (
              <div
                className=" w-[28px] h-[28px] rounded-full cursor-pointer"
                key={item?.name}
                onClick={() => {
                  window.open(item?.jobApp);
                }}
              >
                <img src={item?.iconUrl} alt="" />
              </div>
            );
          })}
        </div>
      </div>
      {/* 第三屏幕 */}
      <div className="flex flex-col items-center bg-blue-900 w-full mt-[40px]">
        <footer className="w-full py-8 ">
          <div className="flex justify-between max-w-[1400px] mx-auto">
            <div>
              <h2 className="font-bold">{t('关于')}</h2>

              {!!datalist?.length &&
                datalist
                  ?.filter((item: any) => {
                    return item.typeCode === 'APP_ABOUT_US' ||item.typeCode ==='KEY_CUSTOMER_CENTER'; //关于我们
                  })
                  .map((item: any, index: number) => (
                    <div
                      onClick={() => {
                        history.push(
                          PageEnum.NOTICELIST +
                            '?type=' +
                            item?.typeCode +
                            '&id=' +
                            item?.id,
                        );
                      }}
                      key={item.id}
                      className="text-xs text-auxiliaryTextColor pt-2"
                    >
                      {item.title}
                    </div>
                  ))}
            </div>

            <div>
              <h2 className="font-bold">{t('学院')}</h2>

              {!!forumMessagePageData?.list?.length &&
                forumMessagePageData?.list.map((item: any, index: number) => (
                  <div
                    onClick={() => {
                      history.push(PageEnum.COLLEGE_LIST + '?id=' + item?.id);
                    }}
                    key={item.id}
                    className="text-xs text-auxiliaryTextColor pt-2"
                  >
                    {item.messageTitle}
                  </div>
                ))}
            </div>

            <div>
              <h2 className="font-bold">{t('条款')}</h2>

              {privacyAgreementList?.map((item) => {
                return (
                  <div
                    onClick={() => {
                      history.push(
                        PageEnum.PRIVACY_POLICY + '?type=' + item?.keyCode,
                      );
                    }}
                    key={item.keyCode}
                    className="text-xs text-auxiliaryTextColor pt-2"
                  >
                    {item.title}
                  </div>
                );
              })}
            </div>

            <div>
              <h2 className="font-bold">{t('联系我们')}</h2>
              <p className="text-xs text-auxiliaryTextColor pt-2">
                <a className="cursor-pointer" href={`mailto:${emialUrl}`}>
                  {t('邮箱')} ：{emialUrl}
                </a>
              </p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};
