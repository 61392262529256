import { IcBaselineApple } from '@/assets/icons/pcHome/IcBaselineApple';
import { IcomoonFreeAndroid } from '@/assets/icons/pcHome/IcomoonFreeAndroid';
import { GetCoinListTypeEnum, WalletTypeEnum } from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import ThemeEnum from '@/enums/themeEnum';
import { cn } from '@/utils';
import { ScrollShadow } from '@nextui-org/react';
import { history, useModel } from '@umijs/max';
import { useUpdateEffect } from 'ahooks';
import { useTheme } from 'next-themes';
import QRCode from 'qrcode.react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PcHomeBottom from './components/PcHomeBottom';
export default () => {
  const { appInfo } = useModel('appInfo');
  const { isMobile } = useModel('system');
  const { t } = useTranslation();

  useUpdateEffect(() => {
    if (isMobile) {
      history.push(PageEnum.HOME);
    }
  }, [isMobile]);

  const { theme } = useTheme();

  const { user } = useModel('user');
  const isLogin = !!user?.id;

  const page2Img = useMemo(() => {
    if (theme === ThemeEnum.dark) {
      return require(`@/assets/img/PcHome/home2dark.png`);
    }
    return require(`@/assets/img/PcHome/home2.png`);
  }, [theme]);

  const page4Img = useMemo(() => {
    if (theme === ThemeEnum.dark) {
      return require(`@/assets/img/PcHome/home4dark.png`);
    }
    return require(`@/assets/img/PcHome/home4.png`);
  }, [theme]);

  const page3Img = useMemo(() => {
    if (theme === ThemeEnum.dark) {
      return require(`@/assets/img/PcHome/home3dark.png`);
    }
    return require(`@/assets/img/PcHome/home3light.png`);
  }, [theme]);

  return (
    <div className="fixed top-[60px] bottom-0 flex  flex-col items-center   left-0 right-0 z-[9999] bg-background">
      <ScrollShadow hideScrollBar className="h-full w-full">
        <div className=" w-full flex flex-col items-center ">
          {/* 页面 1 */}

          <div className="bg-[#17151b] w-full flex justify-center">
            <div
              className="h-[850px] w-[1920px] flex items-center justify-between px-9"
              style={{
                backgroundImage: `url('${require(`@/assets/img/PcHome/home1.jpg`)}')`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover', // 保持图片比例，覆盖整个背景
                backgroundPosition: 'center', // 居中对齐背景图
              }}
            >
              <div>
                <div className="text-[60px] w-[830px] text-[#fdfdfe]">
                  {appInfo?.APP_BASE_NAME??t("加载中")                        }
                  {t('可进行多种货币和多种方式交易')}
                </div>
                <div className="mt-[20px] text-[16px] text-[#fdfdfe]">
                  {t('稳定的交易所，帮您轻松赚币')}
                </div>
                <div
                  onClick={() => {
                    if (isLogin)
                      history.push(
                        '/home?type=' + GetCoinListTypeEnum.CONTRACT,
                      );
                    else history.push(PageEnum.LOGIN);
                  }}
                  className="!bg-[#fff] sm:cursor-pointer px-14 mt-[112px] inline-block text-center leading-[58px] text-[#000] h-[58px] !rounded-[50px]"
                >
                  {t('立即交易')}
                </div>
              </div>
            </div>
          </div>

          {/* 页面 2 */}
          <div className=" w-full pt-[190px] dark:bg-[#6A83A91A] bg-[#6A83A91A]">
            <div className="flex flex-col items-center justify-center  text-backContrastColor ">
              <div className="pt-[40px] text-[60px]">
                {t('数字货币交易，我们是专业的')}
              </div>
              <div className="text-[18px] text-auxiliaryTextColor mt-[12px]">
                {t('最低的手续费，更快捷的交易，强劲的API以及更多')}
              </div>

              <div className="px-[120px]">
                <img className="w-[967px] mt-[100px]" src={page2Img} alt="" />
              </div>
            </div>
          </div>

          {/* 页面 3 */}
          <div className="flex justify-between pt-[218px] pb-[218px] w-[1200px] ">
            <img className="h-[488px]" src={page3Img} />
            <div>
              <div className="text-[40px] text-backContrastColor mb-[8px]">
                {t('我们的核心产品')}
              </div>
              <div className="text-[14px] text-auxiliaryTextColor">
                {t('您可以选择丰富的交易方式')}
              </div>
              <div className="mt-[56px]">
                <div
                  onClick={() => {
                    history.push(
                      PageEnum.HOME + '?type=' + WalletTypeEnum.CONTRACT,
                    );
                  }}
                  className="min-h-[76px] py-1 mb-[12px] w-[430px] border-1 border-borderColor flex justify-between items-center px-[26px] rounded-md duration-300
                             hover:border-backContrastColor cursor-pointer"
                >
                  <div className="flex items-center">
                    <img
                      className="w-[36px] h-[36px]  mr-[8px]"
                      src={require(`@/assets/img/homeCore1.png`)}
                      alt=""
                    />
                    <div>
                      <div className="text-[16px] text-backContrastColor">
                        {t('合约交易')}
                      </div>
                      <div className="text-[14px] pr-1 text-auxiliaryTextColor mt-[5px]">
                        {t('又称期货交易或合约式交易')}
                      </div>
                    </div>
                  </div>
                  <img
                    className="w-[24px] h-[24px] "
                    src={require(`@/assets/img/homeRight.png`)}
                    alt=""
                  />
                </div>
                <div
                  onClick={() => {
                    history.push(
                      PageEnum.HOME + '?type=' + WalletTypeEnum.COIN,
                    );
                  }}
                  className="min-h-[76px] py-1 mb-[12px] w-[430px] border-1 border-borderColor flex justify-between items-center px-[26px] rounded-md duration-300
                             hover:border-backContrastColor cursor-pointer"
                >
                  <div className="flex items-center">
                    <img
                      className="w-[36px] h-[36px]  mr-[8px]"
                      src={require(`@/assets/img/homeCore2.png`)}
                      alt=""
                    />
                    <div>
                      <div className="text-[16px] text-backContrastColor">
                        {t('币币交易')}
                      </div>
                      <div className="text-[14px] pr-1 text-auxiliaryTextColor mt-[5px]">
                        {t('一种加密货币兑换另外一种加密货币')}
                      </div>
                    </div>
                  </div>
                  <img
                    className="w-[24px] h-[24px] "
                    src={require(`@/assets/img/homeRight.png`)}
                    alt=""
                  />
                </div>

                <div
                  onClick={() => {
                    history.push(PageEnum.SWAP);
                  }}
                  className="min-h-[76px] py-1 mb-[12px] w-[430px] border-1 border-borderColor flex justify-between items-center px-[26px] rounded-md duration-300
                             hover:border-backContrastColor cursor-pointer"
                >
                  <div className="flex items-center">
                    <img
                      className="w-[36px] h-[36px] mr-[8px]"
                      src={require(`@/assets/img/homeCore3.png`)}
                      alt=""
                    />
                    <div>
                      <div className="text-[16px] text-backContrastColor">
                        {t('闪兑')}
                      </div>
                      <div className="text-[14px] pr-1 text-auxiliaryTextColor mt-[5px]">
                        {t('可轻松进行加密货币转换')}
                      </div>
                    </div>
                  </div>
                  <img
                    className="w-[24px] h-[24px] "
                    src={require(`@/assets/img/homeRight.png`)}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* 页面 4 */}
          <div className=" w-full pt-[230px]  dark:bg-[#6A83A91A] bg-[#6A83A91A]">
            <div className="flex flex-col items-center justify-center text-[40px] text-backContrastColor ">
              <div className="pt-[40px]">{t('多元化的产品服务')}</div>
              <div className="text-[14px] text-auxiliaryTextColor mt-[8px]">
                {t('选择更多服务，探索无限可能')}
              </div>
              <div className="flex gap-[102px] mt-[72px]">
                <div className="flex items-center">
                  <img
                    className="w-[42px] h-[42px] mr-[5px]"
                    src={require(`@/assets/img/homeProduct1.png`)}
                    alt=""
                  />
                  <div className="flex flex-col">
                    <span className="text-[20px] mb-2 text-backContrastColor">
                      {t('多端操作')}
                    </span>
                    <span className="text-auxiliaryTextColor text-[14px]">
                      {t('多端协同操作，共享一个账号')}
                    </span>
                  </div>
                </div>
                <div className="flex items-center">
                  <img
                    className="w-[42px] h-[42px] mr-[5px]"
                    src={require(`@/assets/img/homeProduct2.png`)}
                    alt=""
                  />
                  <div className="flex flex-col">
                    <span className="text-[20px] mb-2 text-backContrastColor">
                      {t('稳定')}
                    </span>
                    <span className="text-auxiliaryTextColor text-[14px]">
                      {t('低延迟、高报酬')}
                    </span>
                  </div>
                </div>
                <div className="flex items-center">
                  <img
                    className="w-[42px] h-[42px] mr-[5px]"
                    src={require(`@/assets/img/homeProduct3.png`)}
                    alt=""
                  />
                  <div className="flex flex-col">
                    <span className="text-[20px] mb-2 text-backContrastColor">
                      {t('社区')}
                    </span>
                    <span className="text-auxiliaryTextColor text-[14px]">
                      {t('在这里发现更多财富机会')}
                    </span>
                  </div>
                </div>
              </div>
              <div className="px-50px">
                <img className="w-[693px] mt-[100px]" src={page4Img} alt="" />
              </div>
            </div>
          </div>

          {/* 页面 5 */}
          <div className="flex items-center justify-center pt-[260px]">
            <img
              className="h-[410px] mr-[113px]"
              src={require(`@/assets/img/PcHome/home5.png`)}
              alt=""
            />
            <div>
              <div>
                <div className="text-[40px] text-backContrastColor mb-[8px]">
                  {t('随时交易，极速成交')}
                </div>
                <div className="text-[14px] text-auxiliaryTextColor mb-[32px]">
                  {t('支持IPhone、Andriod下载')}
                </div>
              </div>
              <div className="flex">
                <div
                  className={cn(
                    'mr-[20px] px-5 h-[48px] cursor-pointer rounded-[25px] flex items-center justify-center',
                    {
                      'bg-[#CCD2DC] text-[#000]': theme === ThemeEnum.dark,
                      'bg-[#000] text-[#fff]': theme === ThemeEnum.light,
                    },
                  )}
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href = appInfo?.IOS_DOWNLOAD_URL;
                    link.download = ''; // 可以设置文件名
                    link.style.display = 'none';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }}
                >
                  <IcBaselineApple className="text-xl" />

                  <span className="ml-2">{t('IPhone 下载')}</span>
                </div>

                <div
                  className={cn(
                    'mr-[20px] px-5 h-[48px] cursor-pointer rounded-[25px] flex items-center justify-center',
                    {
                      'bg-[#CCD2DC] text-[#000]': theme === ThemeEnum.dark,
                      'bg-[#000] text-[#fff]': theme === ThemeEnum.light,
                    },
                  )}
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href = appInfo?.AND_DOWNLOAD_URL;
                    link.download = ''; // 可以设置文件名
                    link.style.display = 'none';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }}
                >
                  {/* <img
                    className="w-[24px] h-[24px] mr-[4px]"
                    src={require(`@/assets/img/homeAndroid.png`)}
                    alt=""
                  /> */}
                  <IcomoonFreeAndroid />
                  <span className="ml-2">{t('Andriod 下载')}</span>
                </div>
              </div>
              <div className="flex items-center  w-full mt-[60px]">
                <div className="flex flex-col items-center justify-center mr-[24px]">
                  <div className="w-[150px] flex h-[150px] border-1 box-content border-[#3D4858]">
                    <QRCode
                      className=" m-auto"
                      value={appInfo?.IOS_DOWNLOAD_URL}
                      size={120} // 二维码的大小
                    />
                  </div>
                  <div className="mt-[10px] text-[12px] text-auxiliaryTextColor">
                    {t('IPhone 下载')}
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center ">
                  <div className="w-[150px] flex h-[150px] border-1 box-content border-[#3D4858]">
                    <QRCode
                      className=" m-auto"
                      value={appInfo?.AND_DOWNLOAD_URL}
                      size={120} // 二维码的大小
                    />
                  </div>
                  <div className="mt-[10px] text-[12px] text-auxiliaryTextColor">
                    {t('Andriod 下载')}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PcHomeBottom />
        </div>
      </ScrollShadow>
    </div>
  );
};
