import type { SVGProps } from 'react';

export function PCHomeRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" stroke="white" />
      <path
        d="M28.3965 23.9807C28.3967 24.4611 28.1951 24.9231 27.8336 25.2711L21.8543 31.7959C21.5369 32.1422 20.973 32.1875 20.5947 31.8969C20.2166 31.6063 20.1672 31.0898 20.4846 30.7434L26.5346 24.143C26.5822 24.1002 26.609 24.0418 26.609 23.9809C26.609 23.9199 26.5822 23.8613 26.5346 23.8188L26.4834 23.7647L20.4846 17.2186C20.1672 16.8721 20.2166 16.3557 20.5947 16.065C20.973 15.7744 21.5369 15.8195 21.8543 16.166L27.8336 22.6902C28.1951 23.0383 28.3967 23.5002 28.3965 23.9807Z"
        fill="currentColor" />
    </svg>

  );
}
